<template>
  <div class="w-full">
    <form class="stdEl bg-cardset bg-orange-100 sm:p-8" @submit.prevent="submit">
      <InputLabel class="mt-4">
        {{ $t('photoTasksOrGuestbook') }}
        <popover-button explanation='photoTasksOrGuestbookExpl' />
      </InputLabel>
      <select v-model="form.action_type" @change="taskRelevantChange">
        <option value="phototasks"  v-html="$t('phototasks')" />
        <option value="guestbook" v-html="$t('guestbook')" />
      </select>
      <div class="lg:flex justify-between mt-4">
        <div class="w-full mb-4 lg:w-1/3">
          <InputLabel for="language">
            {{ $t('languageOnCards') }}
          </InputLabel>
          <select @change="taskRelevantChange" id="language" name="language" v-model="form.language">
            <option value="de" v-html="$t('german')"  />
            <option value="en" v-html="$t('english')" />
            <option value="fr" v-html="$t('french')" />
            <option value="es" v-html="$t('spanish')" />
          </select>
        </div>
        <div class="w-full mb-4 lg:w-1/3">
          <InputLabel for="event_name">
            {{ $t('eventname') }} <span class="font-normal text-sm">({{ $t('optional') }})</span>
            <popover-button explanation='eventnameExpl' />
          </InputLabel>
          <TextInput
            id="event_name"
            name="event_name"
            v-model="form.event_name"
            :placeholder="$t('eventNameDefault')"
            :onchange="formElementChange"
          />
        </div>
        <div class="w-full mb-4 lg:w-1/4">
          <InputLabel for="event_at">
            {{ $t('eventOn') }}
            <popover-button explanation='eventOnExpl' />
          </InputLabel>
          <TextInput
            id="event_at"
            name="event_at"
            v-model="form.event_at"
            type="date"
            placeholder="yyyy-mm-dd"
            :onchange="formElementChange"
            required
          />
        </div>
      </div>

      <div class="mb-4">
        <InputLabel for="nameline">
          {{ $t('name_s') }}
          <popover-button explanation='name_sExpl' />
        </InputLabel>
        <TextInput
          id="nameline"
          name="nameline"
          v-model="form.nameline"
          :onchange="taskRelevantChange"
          :placeholder="placeholderNameShort[form.language]"
          required
        />
      </div>

      <div v-if="form.action_type === 'phototasks'">
        <InputLabel class="mt-4">
          {{ $t('hostAddressModeQuestion') }}
        </InputLabel>
        <select v-model="form.host_address_mode" @change="taskRelevantChange">
          <option value="wedding"  v-html="$t('hostAddrModeWedding')" />
          <option value="birthday" v-html="$t('hostAddrModeBirthday')" />
          <option value="jubilee"  v-html="$t('hostAddrModeJubilee')" />
          <option value="host"     v-html="$t('hostAddrModeHost')" />
          <option value="names"    v-html="$t('hostAddrModeNames')" :disabled="!form.nameline || form.nameline == ''"/>
        </select>
      </div>
      <InputLabel class="mt-4">
        {{ $t('cardStyle') }}
      </InputLabel>
      <carousel-3d ref="carouselFront" @before-slide-change="onBeforeSlideChangeFront" :width="carouselWidth" :height="carouselWidth*(426.0/600.0)" :startIndex="fontIndSel">
        <slide v-for="(slide, i) in fontData" :index="i" :key="i" class="shadow">
          <picture>
            <source :srcset="'/images/previews/' + form.language + '/' + fontData[i] + '/front.webp 600w, /images/previews/' + form.language + '/' + fontData[i] + '/front-400w.webp 400w'" type="image/webp">
            <source :srcset="'/images/previews/' + form.language + '/' + fontData[i] + '/front.jpg  600w, /images/previews/' + form.language + '/' + fontData[i] + '/front-400w.jpg  400w'" type="image/jpeg">
            <img :src="'/images/previews/' + form.language + '/' + fontData[i] + '/front.jpg'" :alt="fontData[i]">
          </picture>
        </slide>
      </carousel-3d>

      <div v-if="form.action_type === 'guestbook'">
        <InputLabel>
          {{ $t('guestbookPrompt') }}
        </InputLabel>
        <TextInput
              v-model="guestbook_prompt"
            >
        </TextInput>
      </div>
      <div v-if="form.action_type === 'phototasks'">
        <InputLabel>
          {{ $t('photoTasks') }}
          <popover-button explanation='photoTasksExpl' />
        </InputLabel>
        <div class="flex items-center font-bold">
          <Checkbox :checked="preselectAllState !== 'inactive'"
                    :indeterminate="preselectAllState === 'indeterminate'"
                    :onchange="preselectAll" />
          <div class="ml-2 text-base">
            {{ $t('preselectAll') }}
          </div>
        </div>
        <div class="flex items-center font-bold">
          <Checkbox :checked="preselectWeddingState !== 'inactive'"
                    :indeterminate="preselectWeddingState === 'indeterminate'"
                    :onchange="preselectWedding" />
          <div class="ml-2 text-base">
            {{ $t('preselectWedding') }}
          </div>
        </div>
        <div v-if="(form.host_address_mode === 'jubilee') || (form.host_address_mode === 'host')"
            class="flex items-center font-bold">
          <Checkbox :checked="preselectFemaleState !== 'inactive'"
                    :indeterminate="preselectFemaleState === 'indeterminate'"
                    :onchange="preselectFemale" />
          <div class="ml-2 text-base">
            {{ $t('preselectFemale') }}
          </div>
        </div>
        <div v-if="(form.host_address_mode === 'jubilee') || (form.host_address_mode === 'host')"
            class="flex items-center font-bold">
          <Checkbox :checked="preselectMaleState !== 'inactive'"
                    :indeterminate="preselectMaleState === 'indeterminate'"
                    :onchange="preselectMale" />
          <div class="ml-2 text-base">
            {{ $t('preselectMale') }}
          </div>
        </div>
        <hr class="h-px my-2 bg-gray-800 border-0">
        <div class="flex" v-if="loadingTasks">
          <img class="mr-1 h-6" src="/images/loadingImage.gif" :alt="$t('loading')"> {{ $t('loading') }}
        </div>
        <div  class="mb-4" v-else>
          <div v-for="task in tasks" :key="task.id + '_' + task.host_index" class="flex items-center mb-2">
            <Checkbox :checked="task.active" v-model="task.active" :onchange="updatePreselect" />
            <div class="ml-2 text-base">
              {{ task.text }}
            </div>
          </div>
          <div v-for="task in individualTasks" :key="task.id" class="flex items-center mb-2">
            <Checkbox :checked="task.active" v-model="task.active" />
            <TextInput
              class="ml-2"
              v-model="task.text"
              :placeholder="$t('exampleIndividualQuestion')"
              @input.stop="individualTasksMaintain(task.id)"
            >
            </TextInput>
          </div>
        </div>

        <hr class="h-px my-2 bg-gray-800 border-0">
        {{ $t('choice') }} {{ nof_copies }} {{ $t('photoTasks') }}
        <hr class="h-px mt-2 mb-4 bg-gray-800 border-0">
      </div>

      <InputLabel class="mt-2">
        <div class="flex items-center font-normal mb-4">
          <Checkbox @change="formElementChange" id="terms" name="terms" v-model="form.terms" required />
          <div class="ml-2 text-base">
            <i18n-t keypath="agreeTermsCard" tag="span">
              <template #termsLink>
                <Link href="/terms">{{ $t('terms') }}</Link>
              </template>
              <template #gdprLink>
                <Link href="/gdpr">{{ $t('privacyPolicy') }}</Link>
              </template>
            </i18n-t>
          </div>
        </div>
      </InputLabel>

      <PrimaryButton :class="{'opacity-25': submitting}">
        <font-awesome-icon :icon="custom_icon ? custom_icon : 'magic'" class="mr-2" />
          <span v-if="$page.props.auth.user" v-html="custom_button ? $t(custom_button) : $t('createCardset')" />
          <span v-else v-html="custom_button ? $t(custom_button) : $t('previewHint')" />
      </PrimaryButton>
    </form>
  </div>
</template>

<script>
  import PopoverButton from '@/Shared/PopoverButton.vue'
  import PrimaryButton from '@/Components/PrimaryButton.vue'
  import SecondaryButton from '@/Components/SecondaryButton.vue'
  import { Link, router } from '@inertiajs/vue3';
  import { Carousel3d, Slide } from 'vue3-carousel-3d';
  import Checkbox from '@/Components/Checkbox.vue';
  import InputLabel from '@/Components/InputLabel.vue';
  import TextInput from '@/Components/TextInput.vue';

  import "vue3-carousel-3d/dist/index.css"

  export default
  {
    props: ['custom_icon', 'custom_button', 'direct_checkout'],
    created() {
      //if (Object.keys(this.$page.errors).length) {
      //  if (typeof gtag !== 'undefined') {
      //    gtag( 'event', 'error_input_backend');
      //  }
      //}
      this.majorUpdate(null);
    },
    mounted() {
      this.form.language = this.$i18n.locale;
      this.taskRelevantChange();
    },
    data() {
      return {
        submitting: false,
        placeholderNameShort:
          {en: "Betty and John", de: "Laura und Marc", fr: "Aurélie et Ludovic", es: "María y Pablo"},
        form: {
          language: "de",
          event_name: "",
          event_at: null,
          nameline: null,
          action_type: 'phototasks',
          host_address_mode: 'wedding',
          terms: 0,
          tasks: [],
          individualTasks: []
        },
        fontData: [ 'cookie',
                    'amatic',
                    'arabella',
                    'coolvetica',
                    'greatvibes',
                    'montez',
                    'oswald',
                    'pristina',
                    'rochester',
                    'sacramento',
                    'adventpro',
                    'aladin',
                    'aramis',
                    'grandhotel',
                    'lobstertwo',
                    'satisfy'
        ],
        fontIndSel: 0,
        tasks: [],
        guestbook_prompt: '',
        individualTasks:
        [
          {
            id: 0,
            text: '',
            active: false
          }
        ],
        loadingTasks: true,
        preselectAllState: 'active',
        preselectWeddingState: 'active',
        preselectFemaleState: 'active',
        preselectMaleState: 'active',
      }
    },
    components:
    {
      PopoverButton,
      PrimaryButton,
      SecondaryButton,
      Link,
      Carousel3d,
      Slide,
      Checkbox,
      InputLabel,
      TextInput
    },
    methods:
    {
      onBeforeSlideChangeFront(index) {
        this.fontIndSel = index;
      },
      submit: function()
      {
        this.submitting = true;

        this.form.tasks = [];
        this.form.individualTasks = [];

        if (this.form.action_type === 'phototasks')
        {
          this.tasks.forEach((task) => {
            if (task.active)
              this.form.tasks.push(
                {
                  id: task.id,
                  host_index: task.host_index
                }
              );
          });
          this.individualTasks.forEach((task) => {
            if (task.active)
              this.form.individualTasks.push(task.text);
          });
        }
        else if (this.form.action_type === 'guestbook')
        {
          this.form.individualTasks.push(this.guestbook_prompt);
        }

        this.form.mainfont = this.fontData[this.fontIndSel];
        if (this.direct_checkout)
          this.form.direct_checkout = this.direct_checkout;

        router.post('/cardsets', this.form);

        // Google
        if (typeof gtag !== 'undefined') {
          gtag('event', 'conversion', {
            'send_to': 'AW-633552859/nWM6CPny66gZENv_jK4C'
          });
          gtag( 'event', 'create_cardset');
        }

        // Meta/Facebook
        if (typeof fbq !== 'undefined') {
          fbq('track', 'AddToCart');
        }

        // Microsoft/bing
        if (typeof window.uetq !== 'undefined') {
          window.uetq = window.uetq || [];
          window.uetq.push('event', 'create_cardset', {});
        }
      },

      formElementChange: function(e) {
        if (e && (typeof e.target !== 'undefined') && (typeof gtag !== 'undefined')) {
          gtag( 'event', 'changed_' + e.target.name);
        }
      },
      taskRelevantChange: function(e) {
        let that = this;
        this.loadingTasks = true;

        const formData = new FormData();
        formData.append('language', that.form.language);
        formData.append('host_address_mode', that.form.host_address_mode);
        if (that.form.nameline && that.form.nameline != '')
        {
          formData.append('nameline', that.form.nameline);
        }
        const headers = { 'Content-Type': 'multipart/form-data' };

        axios.post('/tasks', formData, { headers }).then(response => {
          that.tasks = response.data.tasks;
          that.guestbook_prompt = response.data.guestbook_prompt;
          that.loadingTasks = false;
          that.updatePreselect();
        });
        this.majorUpdate(e);
      },
      majorUpdate: function(e) {
        // should be called when major conditions change, e.g. language, kind of event (private/public), address prefix that justifies the deletion of some user data
        this.formElementChange(e);
      },
      nofCopiesChange: function(e) {
        this.formElementChange(e);
        this.$emit('nofcopieschanged', this.nof_copies);
      },
      individualTasksMaintain: function(id) {
        let freeAvailable = false;
        let maxId = -1;
        this.individualTasks.forEach((task) =>
        {
          maxId = Math.max(maxId, task.id);
          if (task.text === '')
            freeAvailable = true;

          if (task.id === id) // task currently typed on
          {
            task.active = (task.text !== '');
          }
        })

        if (!freeAvailable)
        {
          this.individualTasks.push (
            {
              id: maxId + 1,
              text: '',
              active: false
            }
          );
        }
      },
      preselectAll: function(e)
      {
        this.tasks.forEach((task) => task.active = e.target.checked);
        this.updatePreselect();
      },
      preselectWedding: function(e) {
        this.tasks.forEach((task) => {
          if (task.event === 'wedding')
          {
            task.active = e.target.checked;
          }
        });
        this.updatePreselect();
      },
      preselectFemale: function(e) {
        this.tasks.forEach((task) => {
          if ((typeof task.host_index !== "undefined") && (task.host_index === 0))
          {
            task.active = e.target.checked;
          }
        });
        this.updatePreselect();
      },
      preselectMale: function(e) {
        this.tasks.forEach((task) => {
          if ((typeof task.host_index !== "undefined") && (task.host_index === 1))
          {
            task.active = e.target.checked;
          }
        });
        this.updatePreselect();
      },
      updatePreselect: function()
      {
        let anyActive           = false;
        let anyInactive         = false;
        let anyWeddingActive    = false;
        let anyWeddingInactive  = false;
        let anyFemaleActive     = false;
        let anyFemaleInactive   = false;
        let anyMaleActive       = false;
        let anyMaleInactive     = false;

        this.tasks.forEach((task) => {
          if (task.active)
          {
            anyActive = true;
            if (task.event === 'wedding')
              anyWeddingActive = true;
            if (typeof task.host_index !== "undefined")
            {
              if (task.host_index === 0)
                anyFemaleActive = true;
              else if (task.host_index === 1)
                anyMaleActive = true;
            }
          }
          else
          {
            anyInactive = true;
            if (task.event === 'wedding')
              anyWeddingInactive = true;
            if (typeof task.host_index !== "undefined")
            {
              if (task.host_index === 0)
                anyFemaleInactive = true;
              else if (task.host_index === 1)
                anyMaleInactive = true;
            }
          }
        });

        if (anyActive && anyInactive)
          this.preselectAllState = 'indeterminate';
        else if (anyActive)
          this.preselectAllState = 'active';
        else
          this.preselectAllState = 'inactive';

        if (anyWeddingActive && anyWeddingInactive)
          this.preselectWeddingState = 'indeterminate';
        else if (anyWeddingActive)
          this.preselectWeddingState = 'active';
        else
          this.preselectWeddingState = 'inactive';

        if (anyFemaleActive && anyFemaleInactive)
          this.preselectFemaleState = 'indeterminate';
        else if (anyFemaleActive)
          this.preselectFemaleState = 'active';
        else
          this.preselectFemaleState = 'inactive';

        if (anyMaleActive && anyMaleInactive)
          this.preselectMaleState = 'indeterminate';
        else if (anyMaleActive)
          this.preselectMaleState = 'active';
        else
          this.preselectMaleState = 'inactive';
      }
    },
    computed: {
      carouselWidth: function()
      {
        var divWidth = window.innerWidth;

        if (divWidth < 400) {
          return 0.7 * divWidth;
        } else if (divWidth < 800) {
          return 0.6 * divWidth;
        } else if (divWidth < 1000) {
          return 0.4 * divWidth;
        } else {
          return 600.0;
        }
      },
      nof_copies: function()
      {
        const tasks = this.tasks.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.active ? 1 : 0);
        }, 0);
        const individualTasks = this.individualTasks.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.active ? 1 : 0);
        }, 0);
        return tasks + individualTasks;
      }
    }
  }
</script>
